html,
body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: Inter, sans-serif;
  margin: 0;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0px;
  font-family: Inter, sans-serif;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.wealth-pdf-viewer {
  height: 100%;
  width: 100%;
}

.download-proposal-btn-container {
  height: 100%;
  position: absolute;
  top: 90%;
  right: 2.5rem;
}

.download-proposal-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #e4e7ec;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(16, 24, 40, 0.1);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  width: 12.5rem;
}

.download-proposal-icon {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
}

.download-proposal-cta {
  font-size: 0.875rem;
  font-weight: 500;
  color: #101828;
}

.download-proposal-link {
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 0;
}
